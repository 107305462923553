<div
   class="all-width-container"
   [ngClass]="{
      open: isVisibleContainers(),
      'opacity-full': isVisibleContent(),
      'no-padding': config()?.noPadding
   }"
>
   <div
      class="bottom-sheet-container no-select"
      [ngClass]="{
         open: isVisibleContainers(),
         'opacity-full': isVisibleContent(),
         backdrop: !config()?.hideBackdrop
      }"
   >
      <div
         class="bottom-sheet-content bg-light"
         [style]="{ height: config()?.height }"
         [ngClass]="{
            open: isVisibleContent(),
            'bg-white': !config() || !config()?.hasBackgroundColor,
            'has-close-button': config()?.closeButton
         }"
      >
         @if (config()?.closeButton) {
            <span
               (click)="closeBottomSheet()"
               class="position-absolute top-15 right-15 rounded-circle p-2 d-flex flex-column align-items-center justify-content-center cursor-pointer"
            >
               <i class="font-lg text-dark bi bi-x"></i>
            </span>
         }
         @if (component()) {
            <div class="scroll-bar h-100">
               <ng-container *ngComponentOutlet="component()"></ng-container>
            </div>
         }
         @if (template()) {
            <div class="scroll-bar h-100 p-2">
               <div class="font-xsss text-grey-700" dir="auto" [innerHTML]="template()"></div>
            </div>
         }
      </div>
   </div>
</div>
