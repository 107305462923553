import { inject, Injectable, signal } from '@angular/core';
import { User } from '../../utils/user.model';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { ProfileService } from 'src/app/main/profile/services/profile.service';
import { PostType } from 'src/app/utils/post.model';

@Injectable({
   providedIn: 'root'
})
export class MainService {
   private userDataSource: BehaviorSubject<any | null> = new BehaviorSubject(null);
   userData = this.userDataSource.asObservable();

   private otherUserDataSource: BehaviorSubject<any | null> = new BehaviorSubject(null);
   otherUserData = this.otherUserDataSource.asObservable();

   private darkModeResource: BehaviorSubject<boolean | any> = new BehaviorSubject(null);
   darkMode = this.darkModeResource.asObservable();

   private loadPostsSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
   loadPosts = this.loadPostsSource.asObservable();

   private postModalOpenSource = new BehaviorSubject<{ type: PostType } | null>(null);
   postModalOpen = this.postModalOpenSource.asObservable();

   public menuPosition = signal<boolean>(false);
   public headerColor = signal<boolean>(false);
   public sideNavShow = signal<boolean>(false);
   public showSearch = signal<boolean>(false);

   private profileService = inject(ProfileService);

   openPostModal(type: PostType) {
      this.postModalOpenSource.next({ type: type });
      this.postModalOpenSource.next(null);
   }

   getUserInfo(): Observable<User> {
      return this.profileService.getUserInfo();
   }

   setUserInApp(user: User) {
      this.userDataSource.next(null);
      localStorage.setItem('userData', JSON.stringify(user));
      this.userDataSource.next(user);
   }

   setOtherUserInApp(user: User) {
      this.otherUserDataSource.next(null);
      this.otherUserDataSource.next(user);
   }

   clearOtherUser() {
      this.otherUserDataSource.next(null);
   }

   setDarkMode(status: boolean) {
      this.darkModeResource.next(status);
   }

   setLoadPosts(status: boolean) {
      this.loadPostsSource.next(status);
      this.loadPostsSource.next(false);
   }

   getMainUserPromise(): Promise<User> {
      return new Promise<User>((resolve, reject) => {
         return this.userData.pipe(take(1)).subscribe({
            next: (user: User) => {
               if (user) resolve(user);
            },
            complete: () => {}
         });
      });
   }

   getOtherUserPromise(): Promise<User> {
      return new Promise<User>((resolve, reject) => {
         return this.otherUserData.pipe(take(1)).subscribe({
            next: (user: User) => {
               if (user) resolve(user);
            },
            complete: () => {}
         });
      });
   }
}
