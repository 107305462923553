import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../login-signup/services/auth.service';

@Injectable({
   providedIn: 'root'
})
export class AuthGurad {
   constructor(
      private authService: AuthService,
      private router: Router,
      private route: ActivatedRoute
   ) {}
   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> | Promise<boolean | undefined> | boolean {
      return this.authService.isAuthenticated().then(authenticate => {
         if (authenticate) {
            return true;
         } else {
            const returnURL = window.location.pathname;
            this.router.navigate(['/login'], {
               queryParams: { returnURL: returnURL }
            });
            return false;
         }
      });
   }
   canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> | Promise<boolean | undefined> | boolean {
      return this.canActivate(route, state);
   }
}
