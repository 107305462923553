import { Component, inject, Injectable, signal } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

export type BottomSheetConfig = {
   hasBackgroundColor?: boolean;
   notCloseOnChangeRoute?: boolean;
   noPadding?: boolean;
   disableClose?: boolean;
   height?: string;
   closeButton?: boolean;
   hideBackdrop?: boolean;
};

@Injectable({
   providedIn: 'root'
})
export class BottomSheetService<OutputType = any, InputType = any> {
   isVisibleContent = signal(false);
   isVisibleContainers = signal(false);
   component = signal(null);
   template = signal(null);
   data = signal<InputType | null>(null);
   outputData = signal<OutputType | null>(null);
   config = signal<BottomSheetConfig | undefined>(undefined);
   isOpening = signal(false);
   router = inject(Router);
   onClose = new Subject<any>();
   emittedValue = 0;
   routingSubscriber?: Subscription;

   openBottomSheet(component: any, data: InputType, config: BottomSheetConfig | null = null): void {
      if (typeof component === typeof Component) {
         this.component.set(component);
      } else {
         this.template.set(component);
      }
      this.outputData.set(null);
      this.data.set(data);
      this.isVisibleContent.set(true);
      this.isVisibleContainers.set(true);
      if (config) {
         this.config.set(config);
      }
      this.isOpening.set(true);
      this.routingSubscriber?.unsubscribe();
      if (!config || !config.notCloseOnChangeRoute) {
         this.routingSubscriber = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
               this.closeBottomSheet();
            }
         });
      }
      setTimeout(() => {
         this.isOpening.set(false);
      }, 100);
   }

   closeBottomSheet(): void {
      this.routingSubscriber?.unsubscribe();
      this.isVisibleContent.set(false);

      setTimeout(() => {
         this.data.set(null);
         this.config.set(undefined);
         this.component.set(null);
         this.isVisibleContainers.set(false);
         this.emittedValue++;
         this.onClose.next({ value: this.emittedValue });
      }, 300);
   }
}
