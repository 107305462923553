import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BankAccountInfo, BuyQuiz, CbRequest, CbTransfer, Transaction, WalletAmount } from '../models/walletDataModels';
import { User } from 'src/app/utils/user.model';

const BASE_URL = environment.WALLET_API_URL;
@Injectable({
   providedIn: 'root'
})
export class WalletService {
   constructor(private http: HttpClient) {}

   newWallet(): Observable<any> {
      return <Observable<any>>this.http.post(`${BASE_URL}wallet`, null);
   }

   getCurrentCB(): Observable<WalletAmount> {
      return <Observable<WalletAmount>>this.http.get(`${BASE_URL}getwalletamount`);
   }

   submitCB(data: FormData): Observable<any> {
      return <Observable<any>>this.http.post(`${BASE_URL}cbchargemailer`, data);
   }

   transferCB(data: CbTransfer): Observable<any> {
      return <Observable<any>>this.http.post(`${BASE_URL}wtowtransferbywalletid`, data);
   }

   buyQuiz(data: BuyQuiz): Observable<any> {
      return <Observable<any>>this.http.post(`${BASE_URL}shoppingtrasfer`, data);
   }

   getTransactionHistory(): Observable<Transaction[]> {
      return this.http.get<Transaction[]>(`${BASE_URL}transactionrecords`);
   }

   getUserInfoByWallet(wallet_id: string): Observable<User> {
      return this.http.post<User>(`${BASE_URL}getwalletidinfo`, { wallet_id: wallet_id });
   }

   promotePost(data: any): Observable<any> {
      return this.http.post<any>(`${BASE_URL}promotion/promote-post`, data);
   }

   getWalletIdByUserId(userId: number): Observable<{ walletId: string }> {
      return this.http.get<{ walletId: string }>(`${BASE_URL}wallet-id-by-user-id?userId=${userId}`);
   }

   transferByUserId(data: { toUserId: number; amount: number; comment: string }): Observable<any> {
      return this.http.post<{ walletId: string }>(`${BASE_URL}wallet-transfer-by-user-id`, data);
   }

   createBankAccountInfo(data: BankAccountInfo) {
      return this.http.put(`${BASE_URL}bank-account-info`, data);
   }

   getUserBankAccountInfo(userId?: number): Observable<BankAccountInfo> {
      let httpParams = new HttpParams();
      if (userId) {
         httpParams = httpParams.append('userId', userId.toString());
      }
      return this.http.get<BankAccountInfo>(`${BASE_URL}bank-account-info`, { params: httpParams });
   }

   deleteUserBankAccountInfo(userId?: number): Observable<BankAccountInfo> {
      let httpParams = new HttpParams();
      if (userId) {
         httpParams = httpParams.append('userId', userId.toString());
      }
      return this.http.delete<BankAccountInfo>(`${BASE_URL}bank-account-info`, { params: httpParams });
   }
}
