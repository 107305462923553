import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, take } from 'rxjs';
import { ChatService } from 'src/app/main/chat/services/chat.service';
import { WebsocketService } from 'src/app/main/chat/services/websocket.service';
import { MainService } from 'src/app/shared/Services/main.service';
import { Roles, User } from 'src/app/utils/user.model';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.AUTH_API_URL + 'auths/';
const ACCAOUNT_URL = environment.AUTH_API_URL + 'accounts/';

@Injectable({
   providedIn: 'root'
})
export class AuthService {
   private loggedIn: boolean = false;
   helper = new JwtHelperService();

   private chatService = inject(ChatService);
   private http = inject(HttpClient);
   private mainService = inject(MainService);
   private websocketService = inject(WebsocketService);

   constructor() {
      this.loadToken();
   }

   loadToken() {
      const token = localStorage.getItem('token');
      if (token) {
         this.loggedIn = true;
      }
   }

   UserHasRole(roles: Roles[]): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
         return this.mainService.userData.pipe(take(1)).subscribe({
            next: (user: User) => {
               if (user) resolve(roles.some(role => (user.role as any[]).find(item => item.name === role)));
               resolve(false);
            },
            complete: () => {}
         });
      });
   }

   UserHasAssignment(types: number[]): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
         return this.mainService.userData.pipe(take(1)).subscribe({
            next: (user: User) => {
               if (user) resolve(types.some(type => user.assignment.find(item => item.type_id === type)));
               resolve(false);
            },
            complete: () => {}
         });
      });
   }

   setLoggedIn(tokenBody: { access: string; refresh: string }) {
      this.loggedIn = true;
      localStorage.setItem('token', tokenBody.access);
      localStorage.setItem('refreshToken', tokenBody.refresh);
   }

   isAuthenticated(): Promise<boolean> {
      return new Promise<boolean>(resolve => {
         resolve(this.loggedIn);
      });
   }
   getOTP(phoneNumber: string): Observable<any> {
      return <Observable<any>>this.http.get(`${BASE_URL + phoneNumber}`);
   }
   verifyOTP(otp: string, phoneNumber: string): Observable<any> {
      return <Observable<any>>this.http.post(`${BASE_URL + phoneNumber}`, { otp: otp });
   }

   registerPersonalInfo(data: FormData) {
      return <Observable<any>>this.http.post(`${ACCAOUNT_URL}setregisterinfo`, data);
   }

   loginWithUsernameAndPassword(data: any) {
      return this.http.post<any>(`${environment.AUTH_API_URL}login`, data);
   }

   logOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userData');
      this.websocketService.stopSocket();
      this.chatService.cleanChatHistory();
   }

   refreshToken(refreshToken: string): Observable<any> {
      const token = localStorage.getItem('token');
      return this.http.post<any>(`${environment.AUTH_API_URL}token/refresh`, { refresh: refreshToken });
   }
}
