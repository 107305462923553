import { Component, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingComponent } from './shared/components/loading component/loading.component';
import { RouterOutlet } from '@angular/router';
import { BottomSheetComponent } from './shared/components/bottom-sheet/bottom-sheet.component';
import { BottomSheetService } from './shared/Services/bottom-sheet.service';
import { NgClass } from '@angular/common';

@UntilDestroy()
@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
   standalone: true,
   imports: [RouterOutlet, LoadingComponent, BottomSheetComponent, NgClass]
})
export class AppComponent {
   title = 'Tiktera';

   animateTransition = signal<boolean>(true);

   constructor(
      private swUpdate: SwUpdate,
      public bottomSheetService: BottomSheetService
   ) {
      this.checkForUpdate();
   }

   checkForUpdate() {
      this.swUpdate.versionUpdates.pipe(untilDestroyed(this)).subscribe({
         next: value => {
            if (value.type === 'VERSION_READY') {
               window.location.reload();
            }
         }
      });
   }
}
