import { AppComponent } from './app/app.component';
import { isDevMode, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgHttpCachingModule, NgHttpCachingConfig, NgHttpCachingStrategy } from 'ng-http-caching';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { routes } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LoadingScreenInterceptor } from './app/shared/components/loading component/loading.intreceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { WebsocketService } from './app/main/chat/services/websocket.service';
import { PostService } from './app/main/home/services/post.service';
import { ProfileService } from './app/main/profile/services/profile.service';
import { WalletService } from './app/main/wallet/services/wallet.service';
import { QuizLandService } from './app/main/quiz-land/services/quiz-land.service';
import { AuthService } from './app/login-signup/services/auth.service';
import { AuthInterceptor } from './app/utils/guard/auth.interceptor';
import { EditorGurad } from './app/utils/guard/editor.guard';
import { AuthGurad } from './app/utils/guard/auth.guard';
import { NumberBriefsPipe } from './app/shared/pipes/html-content/number-briefs.pipe';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { ImageQualityPipe } from './app/shared/pipes/Image/image-quality.pipe';

const ngHttpCachingConfig: NgHttpCachingConfig = {
   //All get APIs are cached for 1 minute
   lifetime: 1000 * 60,
   allowedMethod: ['GET', 'HEAD'],
   cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL
};

bootstrapApplication(AppComponent, {
   providers: [
      importProvidersFrom(
         BrowserModule,
         NgbModule,
         //cache APIs
         NgHttpCachingModule.forRoot(ngHttpCachingConfig),
         ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
         })
      ),
      AuthGurad,
      EditorGurad,
      AuthInterceptor,
      AuthService,
      QuizLandService,
      WalletService,
      ProfileService,
      PostService,
      WebsocketService,
      NumberBriefsPipe,
      ImageQualityPipe,
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimations(),
      provideRouter(routes, withPreloading(PreloadAllModules)),
      provideExperimentalZonelessChangeDetection()
   ]
}).catch(err => console.error(err));
