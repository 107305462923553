import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../profile/services/profile.service';
import { Chat, ChatObservable, Message, MessagePagination, SocketMessage } from '../models/chat';

const BASE_URL = environment.CHAT_URL;

@Injectable({
   providedIn: 'root'
})
export class ChatService {
   chatHistory: ChatObservable[] = [];
   onlineUsers: number[] = [];

   private chatHistorySource: BehaviorSubject<any> = new BehaviorSubject(null);
   chatHistorySub = this.chatHistorySource.asObservable();

   public isMobileSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
   isMobileSub = this.isMobileSource.asObservable();

   public isChatHistory = signal<boolean>(true);

   private newMessageReceiveSource: BehaviorSubject<any> = new BehaviorSubject(null);
   newMessageReceiveSub = this.newMessageReceiveSource.asObservable();

   private UnreadMessageCountSource: BehaviorSubject<any> = new BehaviorSubject(0);
   UnreadMessageSub = this.UnreadMessageCountSource.asObservable();
   private unreadMessagesCount: number = 0;

   constructor(
      private http: HttpClient,
      private profileService: ProfileService
   ) {}

   addNewChatToHistory(chat: ChatObservable, newRoom: boolean = false): void {
      const existingChat = this.chatHistory.find(item => item.roomId === chat.roomId);

      if (existingChat) {
         existingChat.messages = chat.messages;
      } else {
         newRoom ? this.chatHistory.unshift(chat) : this.chatHistory.push(chat);
      }
      this.chatHistorySource.next(this.chatHistory);
   }

   removeRoomById(roomId: string) {
      const chatHistory = this.chatHistory.find(item => item.roomId === roomId);
      if (chatHistory) {
         this.chatHistory = this.chatHistory.filter(chat => chat.roomId !== roomId);
         this.chatHistorySource.next(this.chatHistory);
      }
   }

   refreshChatSub() {
      this.chatHistorySource.next(this.chatHistory);
   }

   async addNewMessageToChat(message: Message, newMessage?: boolean): Promise<void> {
      const index = this.chatHistory.findIndex(chat => chat.roomId === message.chatRoomId);

      if (index !== -1) {
         this.chatHistory[index].messages.push(message);
         this.chatHistory[index].hasNewMessage = newMessage;
         const chat = { ...this.chatHistory.find(chat => chat.roomId === message.chatRoomId) };
         this.chatHistory.splice(index, 1);
         this.chatHistory.unshift(chat as ChatObservable);
         this.chatHistorySource.next(this.chatHistory);
      } else {
         const user = await this.profileService.getUserInfoByIdPromise(message.senderId);
         const chat = {
            userId: user.id,
            username: user.username,
            name: user.name,
            photo: user.photo as string,
            roomId: message.chatRoomId,
            messages: [message],
            created_at: new Date().toUTCString(),
            role: user.role,
            isOnline: true,
            seenMessage: newMessage
         };
         this.addNewChatToHistory(chat);
      }
      this.newMessageReceiveSource.next(message);
   }

   addMessagesToChat(messages: Message[], roomId: string): void {
      const chat = this.chatHistory.find(chat => chat.roomId === roomId);
      if (chat) {
         chat.messages = [...messages];
         this.chatHistorySource.next(this.chatHistory);
      }
   }

   sendIsMobile(value: boolean) {
      this.isMobileSource.next(value);
   }

   getChatListHistory(): Observable<Chat[]> {
      return this.http.get<Chat[]>(`${BASE_URL}chat/rooms`);
   }

   newRoom(data: { calledUserId: number }): Observable<{ roomId: string }> {
      return this.http.post<{ roomId: string }>(`${BASE_URL}chat/new-room`, data);
   }

   getChatHistory(roomId: string, pageNumber: number = 0, pageSize: number = 1000): Observable<MessagePagination> {
      return this.http.get<MessagePagination>(
         `${BASE_URL}chat/messages?chatRoomId=${roomId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
   }

   uploadMediaMessage(
      data: FormData,
      roomId: string,
      mediaType: 'image' | 'video'
   ): Observable<HttpEvent<{ chatRoomId: string; messageId: string; mediaUrl: string }>> {
      const headers = new HttpHeaders({ 'ngsw-bypass': '' });
      if (mediaType === 'image') {
         return this.http.post<{ chatRoomId: string; messageId: string; mediaUrl: string }>(
            `${BASE_URL}chat/upload-image?chatRoomId=${roomId}`,
            data,
            {
               reportProgress: true,
               observe: 'events',
               headers: headers
            }
         );
      }
      return this.http.post<{ chatRoomId: string; messageId: string; mediaUrl: string }>(
         `${BASE_URL}chat/upload-video?chatRoomId=${roomId}`,
         data,
         {
            reportProgress: true,
            observe: 'events',
            headers: headers
         }
      );
   }

   setUploadedMessage(roomId: string, messageId: string, progress?: number) {
      this.chatHistory = this.chatHistory.map(chat => {
         return {
            ...chat,
            messages:
               chat.roomId !== roomId
                  ? chat.messages
                  : chat.messages.map(message => {
                       return {
                          ...message,
                          uploading: message.id === messageId && !progress ? false : message.uploading,
                          loaded: progress
                       };
                    })
         };
      });
      this.chatHistorySource.next(this.chatHistory);
   }

   setOnlineUsers(userList: number[]) {
      this.onlineUsers = userList;
      this.chatHistory = this.chatHistory.map(chat => {
         chat.isOnline = userList.includes(chat.userId);
         return chat;
      });
      this.chatHistorySource.next(this.chatHistory);
   }

   setSeenReceivedMessages(messages: Message[], roomId: string) {
      const seenMessageIds = new Set(messages.map(item => item.id));
      this.chatHistory = this.chatHistory.map(chat => {
         if (chat.roomId === roomId) {
            chat.hasNewMessage = false;
            chat.messages = chat.messages.map(message => {
               if (seenMessageIds.has(message.id)) {
                  return { ...message, seen: true };
               }
               return message;
            });
         }
         return chat;
      });
      this.chatHistorySource.next(this.chatHistory);
   }

   setSeenSentMessage(sentMessage: SocketMessage) {
      this.chatHistory = this.chatHistory.map(chat => {
         if (chat.roomId === sentMessage.chatRoomId) {
            return {
               ...chat,
               messages: chat.messages.map(message => {
                  return { ...message, seen: true };
               })
            };
         }
         return chat;
      });
      this.chatHistorySource.next(this.chatHistory);
   }

   setUserStatus(userId: number, online: boolean) {
      if (online) {
         this.onlineUsers.push(userId);
      } else {
         this.onlineUsers = this.onlineUsers.filter(user => user !== userId);
      }
      const chat = this.chatHistory.find(chat => chat.userId === userId);
      if (chat) {
         chat.isOnline = online;
      }
      this.chatHistorySource.next(this.chatHistory);
   }

   isUserOnline(userId: number) {
      return this.onlineUsers.includes(userId);
   }

   addUnreadMessageCount() {
      this.unreadMessagesCount++;
      this.UnreadMessageCountSource.next(this.unreadMessagesCount);
   }

   removeUnreadMessageCount() {
      this.unreadMessagesCount--;
      this.UnreadMessageCountSource.next(this.unreadMessagesCount);
   }

   setUnreadMessageCount(count: number) {
      this.unreadMessagesCount = count;
      this.UnreadMessageCountSource.next(this.unreadMessagesCount);
   }

   clearUnreadMessageCount() {
      this.unreadMessagesCount = 0;
      this.UnreadMessageCountSource.next(this.unreadMessagesCount);
   }

   seenChat(roomId: string) {
      const chat = this.chatHistory.find(i => i.roomId === roomId);
      if (chat) {
         chat.hasNewMessage = false;
      }
      this.chatHistorySource.next(this.chatHistory);
   }

   cleanChatHistory() {
      this.chatHistory = [];
      this.chatHistorySource.next(this.chatHistory);
   }
}
